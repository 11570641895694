<script setup lang="ts">
const props = defineProps({
    showTooltip: {
        type: Boolean,
        default: true
    }
})

const showTooltip = computed(() => {
    return props.showTooltip;
})
</script>

<template>
    <div class="bg-green-dark text-white flex justify-center items-center rounded-full shrink-0 w-full aspect-square">
        <IconsHatIcon style="width: 80%"/>
        <tippy v-if="showTooltip" to="parent" :hide-on-click="false" interactive>
            <strong>Media sponsor:</strong> Medium Rare Foundation<br/>
            <strong>Country:</strong> Republic of Panama<br/>
            <strong>Contact:</strong> <a target="_blank" href="mailto:mediumrarefoundation@gmail.com" class="underline">mediumrarefoundation@gmail.com</a>
        </tippy>
    </div>
</template>
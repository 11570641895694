<script setup lang="ts">
import { CampaignState } from "~/common/Enums";
import type { SuggestedCampaignDto } from "~~/_api_generated_";

const props = defineProps<{
  campaign: SuggestedCampaignDto;
  isHighestReward?: boolean;
}>();

const isEvaluating = computed(() => {
  if ("state" in props.campaign) {
    return props.campaign.state === CampaignState.EVALUATING;
  }
  return false;
});
</script>

<template>
  <div class="text-black/50 flex flex-wrap items-center gap-3">
    <BrandsIconAndName :theme="campaign?.advertiser?.theme" />
    <span class="w-1 h-1 rounded-full bg-black/50"></span>
    <div v-if="isEvaluating">Evaluating</div>
    <CampaignDateInfo
      v-else-if="campaign.id !== undefined"
      :campaign="campaign"
    />

    <span
      v-if="isHighestReward"
      class="w-1 h-1 rounded-full bg-black/50"
    ></span>
    <span v-if="isHighestReward" class="font-semibold text-sm text-purple">
      Highest reward
      <tippy to="parent">
        Because this post is eligible for more than one campaign, it will be
        automatically assigned to the campaign with the highest reward at the
        time of when campaigns end.
      </tippy>
    </span>
  </div>
</template>

<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_567_574)">
            <path d="M8 8.59766L6.58 9.99099C6.20667 10.3643 6 10.851 6 11.371C6 12.451 6.9 13.331 8 13.331C9.1 13.331 10 12.451 10 11.371C10 10.851 9.79333 10.3577 9.42 9.99099L8 8.59766Z" fill="#FF0000"/>
            <path d="M10.667 3.9987L10.3737 4.36536C9.58699 5.34536 8.00033 4.79203 8.00033 3.53203V1.33203C8.00033 1.33203 2.66699 3.9987 2.66699 8.66536C2.66699 10.612 3.70699 12.312 5.26033 13.2387C4.88699 12.712 4.66699 12.0654 4.66699 11.372C4.66699 10.492 5.01366 9.66536 5.64699 9.0387L8.00033 6.73203L10.3537 9.04536C10.987 9.66536 11.3337 10.492 11.3337 11.3787C11.3337 12.0587 11.127 12.6854 10.767 13.212C12.027 12.4454 12.9603 11.172 13.2403 9.6787C13.6803 7.31203 12.527 5.0787 10.667 3.9987Z" fill="#FF0000"/>
        </g>
        <defs>
            <clipPath id="clip0_567_574">
                <rect width="16" height="16" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>
